.card {
    box-shadow: 0 1px 8px rgb(0 0 0 / 25%);
    padding: 1rem;
    background-color: #fff;
    margin: 2rem auto;
    width: 75rem;
    max-width: 95%;
    border-radius: 12px;
}

.logo{
    max-width:100%
}

.BC{
    color: #d34931;
}

.CW{
    color: #92bc4f;
}

.TECH{
    color:#5eb2e3;
}