body{
height: 100%;
background: #3f3f3f;
color: black;
}

.card {
  box-shadow: 0 1px 8px rgb(0 0 0 / 25%);
  padding: 1rem;
  background-color: white;
  margin: 2rem auto;
  width: 50rem;
  max-width: 95%;
  border-radius: 12px;
}
