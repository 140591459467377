.navStickyWhite {position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 200;
    padding: 10px 0;
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 1px 20px rgb(0 0 0 / 7%);
    font-family: 'Lato', sans-serif !important;
}

.navList{
    padding: 0;
    margin: 0;
    list-style: none;
    display: block;
}

.navListItem{
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    margin-right: 15px;
}